import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
let FavoriteButton = class FavoriteButton extends Vue {
  get isConfigurationSaved() {
    return this.$store.getters["configurator/isConfigurationSaved"];
  }

  get configurationComplete() {
    return this.$store.getters["configurator/configurationComplete"];
  }

  get FavIcon() {
    return this.isConfigurationSaved >= 0 ? "favorite" : "favorite_border";
  }

  clickFavButton() {
    if (this.FavIcon == "favorite_border") {
      this.$store.commit("configurator/saveConfiguration");
      this.$emit("added");
    } else {
      this.goToFavorites();
    }
  }

  goToFavorites() {
    this.$store.commit("setCurrentTab", "tab-favorites");
    this.$store.commit("setProfileOpen", true);
  }

};

__decorate([Prop({
  default: false
})], FavoriteButton.prototype, "canAddToCart", void 0);

__decorate([Prop()], FavoriteButton.prototype, "canAddToCartMeniscati", void 0);

FavoriteButton = __decorate([Component({})], FavoriteButton);
export default FavoriteButton;