import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let LanguageCard = class LanguageCard extends Vue {
  changeLanguage(lang) {
    this.$i18n.locale = lang;
    this.$emit("close-menu-language"); // @ts-ignore

    this.$rest.setCountryCode(lang);
  }

};
LanguageCard = __decorate([Component({})], LanguageCard);
export default LanguageCard;