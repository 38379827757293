import { __decorate } from "tslib";
import StoreLocator from "../StoreLocator.vue";
import { Component, Vue } from "vue-property-decorator";
let StoreLocatorButton = class StoreLocatorButton extends Vue {
  constructor() {
    super(...arguments);
    this.storeLocatorDialog = false;
  }

};
StoreLocatorButton = __decorate([Component({
  components: {
    StoreLocator
  }
})], StoreLocatorButton);
export default StoreLocatorButton;