import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";
let SamplesModelDialog = class SamplesModelDialog extends Vue {
  constructor() {
    super(...arguments);
    this.models = [];
    this.sModel = null;
    this.selectedModel = null;
  }

  mounted() {
    // @ts-ignore
    this.$rest.getSamplesModels().then(r => {
      this.models = Object.values(r.data);
    });
  }

  modelLink(model) {
    return "https://media.thema-optical.com/3d_models/" + _.replace(model.U_THE_MODELLO, "F", "C") + ".png";
  }

  addToCart(model) {
    var _this$colore;

    const row = {
      FinishingTemple: null,
      ItemCode: model.ItemCode,
      TempleCode: null,
      asian_fitting: 0,
      calibro: null,
      coloreMetalloAste: null,
      coloreNomeExtAstaDx: "",
      coloreNomeExtAstaSx: "",
      coloreNomeIntAstaDx: "",
      coloreNomeIntAstaSx: "",
      fontNomeExtAstaDx: "",
      fontNomeExtAstaSx: "",
      fontNomeIntAstaDx: "",
      fontNomeIntAstaSx: "",
      colore_frontale: (_this$colore = this.colore) === null || _this$colore === void 0 ? void 0 : _this$colore.U_THE_COLORE,
      colore_nome: "",
      finishing: null,
      font_nome: "",
      naso: null,
      nome: "",
      nomeExtAstaDx: "",
      nomeExtAstaSx: "",
      nomeIntAstaDx: "",
      nomeIntAstaSx: "",
      quantita: 1,
      abbellitore: "",
      lensesType: "",
      lensdescrDx: "",
      lensdescrSx: "",
      lensDx: null,
      lensSx: null,
      lensSole: null,
      sferaDx: 0.0,
      sferaSx: 0.0,
      cilindroDx: 0.0,
      cilindroSx: 0.0,
      asseDx: 0.0,
      asseSx: 0.0,
      semiDistDx: 0.0,
      semiDistSx: 0.0,
      altPupDx: 0.0,
      altPupSx: 0.0,
      addDx: 0.0,
      addSx: 0.0
    };
    this.addSampleToCart([row]);
  }

  addSampleToCart(newValues) {
    this.$store.dispatch("configurator/cart/addSampleToCart", newValues);
  }

  filteredModels() {
    let self = this;

    if (this.sModel) {
      return this.models.filter(function (m) {
        return _.includes(m.U_THE_MODELLO, self.sModel);
      });
    } else {
      return this.models;
    }
  }

  colorImageUrl() {
    var _this$colore2;

    if (((_this$colore2 = this.colore) === null || _this$colore2 === void 0 ? void 0 : _this$colore2.Category) != "MULTISTRATO") {
      var _this$colore3;

      return (_this$colore3 = this.colore) === null || _this$colore3 === void 0 ? void 0 : _this$colore3.imageUrl;
    } else {
      var _this$colore4;

      return (_this$colore4 = this.colore) === null || _this$colore4 === void 0 ? void 0 : _this$colore4.backImageUrl;
    }
  }

  get colore() {
    return this.$store.state.configurator.colore;
  }

};

__decorate([Prop()], SamplesModelDialog.prototype, "showSampleDialog", void 0);

__decorate([Prop()], SamplesModelDialog.prototype, "darkTheme", void 0);

SamplesModelDialog = __decorate([Component({})], SamplesModelDialog);
export default SamplesModelDialog;