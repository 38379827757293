import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";
import hideMobileKeyboardOnReturn from "../plugins/utils";
let LentiSole = class LentiSole extends Vue {
  constructor() {
    super(...arguments);
    this.tipologia = null;
    this.hideMobileKeyboardOnReturn = hideMobileKeyboardOnReturn;
  }

  isItemActive(item) {
    return this.sunLens && this.sunLens.ItemCode === item.ItemCode;
  }

  get sunLenses() {
    return this.$store.getters["configurator/lenti/sunLenses"];
  }

  get filteredSunLenses() {
    if (!this.tipologia) {
      return this.sunLenses;
    }

    return this.sunLenses.filter(item => {
      var _this$tipologia;

      return item.ItemName.includes((_this$tipologia = this.tipologia) !== null && _this$tipologia !== void 0 ? _this$tipologia : "") && !item.ItemName.includes("NON " + this.tipologia);
    });
  }

  get sunLens() {
    return this.$store.state.configurator.lenteSole;
  }

  set sunLens(value) {
    this.$store.commit("configurator/setSunLensesModel", value);
  }

  get glassType() {
    return this.$store.state.configurator.glassType;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

};

__decorate([Prop({
  default: false
})], LentiSole.prototype, "darkTheme", void 0);

LentiSole = __decorate([Component({
  filters: {
    capitalize: value => {
      return _.capitalize(value);
    }
  }
})], LentiSole);
export default LentiSole;