import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let LanguageButton = class LanguageButton extends Vue {
  get languageFlagUrl() {
    let lang = this.$i18n.locale;
    return "https://thema-optical.com/images/flags/" + lang + ".png";
  }

};
LanguageButton = __decorate([Component({})], LanguageButton);
export default LanguageButton;