import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { Loader } from "google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
let StoreLocator = class StoreLocator extends Vue {
  constructor() {
    super(...arguments);
    this.stores = [];
    this.originalCountry = "";
    this.country = "";
    this.countryCode = "";
    this.countryName = "";
    this.center = {};
    this.markers = [];
    this.clusterStyle = {
      styles: [{
        url: "images/maps/m1.png",
        width: 65,
        height: 65,
        textSize: 15,
        fontWeight: "bold",
        textColor: "#ffffff",
        textAlign: "center"
      }]
    };
    this.activeInfoWindow = {};
    this.filter = "";
    this.showList = false;
    this.stati = [];
    this.rest = axios.create({
      baseURL: process.env.NODE_ENV == "production" ? "" : "http://localhost:8081",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "x-requested-with": "XMLHttpRequest",
        Accept: "application/json, text/javascript, */*; q=0.01"
      }
    });
  }

  async mounted() {
    var cc = "";
    var nn;

    if (!this.google) {
      const loader = new Loader("AIzaSyAgmUQkC09psxX8EYyCRAW-8ZlB0cGm1uA");
      this.google = await loader.load();
    } // @ts-ignore


    this.$rest.getUserCountry().then(response => {
      cc = response.data.country; // @ts-ignore

      this.$rest.getCountryStoresActive().then(response => {
        nn = response.data;

        for (let i = 0; i < nn.length; i++) {
          if (nn[i].Country == cc) {
            this.country = nn[i].NomeStato;
            this.countryCode = nn[i].Country;
            this.initMap();
            this.setCenterPosition();
          }
        }
      });
    });
  }

  get totalStores() {
    return this.stores.length;
  }

  get haveNoStores() {
    return this.markers.length == 0;
  }

  get filteredStores() {
    var self = this;
    var filtrato = [];
    let NameString;

    if (self.filter.length > 0) {
      filtrato = self.stores.filter(function (siu) {
        //Store In Use
        //Restituisci negozi filtrati--> SE nazione impostato e campo testuale vuoto || SE nazione si sta compilando
        if (siu.BuildingFloorRoom === "" || siu.BuildingFloorRoom == null) {
          NameString = siu.U_THE_RAG_SOCIALE;
        } else {
          NameString = siu.BuildingFloorRoom;
        }

        return siu.City.toLowerCase().indexOf(self.filter.toLowerCase()) > -1 || NameString.toLowerCase().indexOf(self.filter.toLowerCase()) > -1 && siu.BuildingFloorRoom.indexOf("**") < 0 && siu.BuildingFloorRoom.indexOf("NON USARE") < 0 && siu.BuildingFloorRoom.indexOf("NON UTILIZZARE") < 0;
      });
      return filtrato;
    }

    return this.stores;
  }

  get statesAvailables() {
    return this.stati.filter(s => {
      return s.Country != null;
    });
  }

  updateStores(cname) {
    var self = this; // var listaStati = [];

    var cn = this.stati.filter(function (st) {
      return st.NomeStato == self.country;
    });
    cname = cn[0] ? cn[0].Country : ""; // cname = this.countryCode;
    // @ts-ignore

    const stores = Vue.prototype.$iperStoresMode == "true" ? this.$rest.getIperStores(cname) : this.$rest.getStores(cname);
    stores.then(function (response) {
      self.stores = response.data.stores;
      self.stati = response.data.countryList;
      self.initMap();
      self.setCenterPosition(); // self.country = response.data.country

      if (!cname) {
        // @ts-ignore
        self.$rest.getUserCountry().then(function (response) {
          self.countryCode = response.data.country; //if(!self.stati){self.stati[0].Country=response.data.country;}

          for (let i = 0; i < self.stati.length; i++) {
            if (self.stati[i].Country == response.data.country) {
              self.country = self.stati[i].NomeStato; // self.countryCode=self.stati[i].Country;
              // console.log(self.stati);
            }
          }
        });
      }
    });
  }

  zoomAndCenter(s) {
    var posizione = this.getPosition(s.U_THE_LATITUDINE, s.U_THE_LONGITUDINE); //SE mancano le coordinate viene fatto puntare il centro nazionale

    this.map.setCenter(posizione); // this.map.setZoom(12);
    // this.map.setCenter(s.position);

    this.map.setZoom(18); // CICLO FOR PER CONTROLLARE TUTTI I MARKER

    for (let i = 0; i < this.markers.length; i++) {
      var NameString; // console.log('marker'+i)
      // CICLO IF PER CONFRONTARLI ALLO STORE SELEZIONATO

      if (this.markers[i].storeData !== null && s.storeData == this.markers[i].storeData) {
        const self = this,
              m = this.markers[i],
              s = m.storeData,
              c = m.storeData;

        if (s.BuildingFloorRoom === "" || s.BuildingFloorRoom == null) {
          NameString = s.U_THE_RAG_SOCIALE;
        } else {
          NameString = s.BuildingFloorRoom;
        }

        let iw_content = '<div class="card-body white-text">' + '<div class="d-flex row">' + '<div class="col justify-content-center align-items-center info-address-column">' + '<p class="white-text" >' + "<b>" + NameString + "</b></br>" + '<div class="separator"></div>' + s.Street + ", <br /> " + s.ZipCode + " " + s.City + "<br />" + '<a href="mailto:' + c.E_Mail + '" class="contact-data white-text" >' + c.E_Mail + "</a><br />" + '<a class="contact-data white-text" href="tel:' + c.Phone1 + '">' + c.Phone1 + "</a>" + "</p>" + "</div>" + "</div>" + "</div>" + '<div class="clearfix"></div>' + "</div>";
        const iw = new this.google.maps.InfoWindow({
          content: iw_content,
          maxWidth: 300
        }); // RENDO VISIBILE IL MARKER DELLO STORE SELEZIONATO E CHIUDO GLI ALTRI

        if (typeof self.infoWindow != "undefined") {
          self.infoWindow.close();
        }

        iw.open(self.map, m);
        self.infoWindow = iw;
        this.google.maps.event.addListener(iw, "domready", function () {
          var el = document.getElementsByClassName("gm-style-iw")[0],
              wr = el.parentElement,
              // eslint-disable-next-line no-unused-vars
          bk = wr.firstChild,
              // eslint-disable-next-line no-unused-vars
          close = wr.getElementsByTagName("button")[0],
              // eslint-disable-next-line no-unused-vars
          closeIcon = document.createElement("i");
        });
      }
    }
  }

  initMap() {
    this.map = new this.google.maps.Map(document.getElementById("map"), {
      zoom: 10
    });
  }

  addMapMarkers() {
    //reset di eventuali markers precedenti
    if (this.cluster && typeof this.cluster.setMap == "function") {
      this.cluster.clearMarkers();
      this.cluster = new MarkerClusterer({});
    }

    this.markers = []; //inserimento nuovi markers

    for (let i = 0; i < this.stores.length; i++) {
      let m,
          // counter,
      store = this.stores[i];

      if (store.U_THE_LATITUDINE && store.U_THE_LATITUDINE != "0" && store.U_THE_LONGITUDINE && store.U_THE_LONGITUDINE != "0") {
        // eslint-disable-next-line no-undef
        m = new this.google.maps.Marker({
          position: this.getPosition(store.U_THE_LATITUDINE, store.U_THE_LONGITUDINE),
          map: this.map,
          icon: "/icons/baseline-place-24px.svg"
        });
        m.storeData = store;
        this.markers.push(m);
      }
    }

    for (var j = 0; j < this.markers.length; j++) {
      var NameString;
      let PhoneNumber = "";
      var MailString = "";

      if (this.markers[j].storeData !== null) {
        const self = this,
              m = this.markers[j],
              s = m.storeData,
              c = m.storeData;

        if (s.BuildingFloorRoom === "" || s.BuildingFloorRoom == null) {
          NameString = s.U_THE_RAG_SOCIALE;
        } else {
          NameString = s.BuildingFloorRoom;
        }

        if (c.E_Mail === "" || c.E_Mail == null) {
          MailString = "";
        } else {
          MailString = c.E_Mail;
        }

        if (c.Phone1 === "" || c.Phone1 == null) {
          PhoneNumber = "";
        } else {
          PhoneNumber = c.Phone1;
        }

        let iw_content = '<div class="card-body white-text">' + '<div class="d-flex row">' + '<div class="col justify-content-center align-items-center info-address-column">' + '<p class="white-text" >' + "<b>" + NameString + "</b></br>" + '<div class="separator"></div>' + s.Street + ", <br /> " + s.ZipCode + " " + s.City + "<br />" + '<a href="mailto:' + MailString + '" class="contact-data white-text" >' + MailString + "</a><br />" + '<a class="contact-data white-text" href="tel:' + PhoneNumber + '">' + PhoneNumber + "</a>" + "</p>" + "</div>" + // '<div class="col justify-content-center align-items-center info-image-column">'+
        // '<img class="img-fluid store-image '+s.CardCode+s.AddressName+'" onerror="setDefaultImageStore(this)" src="/images/stores_images/'+s.CardCode+s.AddressName+'.jpg" alt="'+s.BuildingFloorRoom+'" />'+
        "</div>" + "</div>" + '<div class="clearfix"></div>' + "</div>"; // eslint-disable-next-line no-undef

        const iw = new this.google.maps.InfoWindow({
          content: iw_content,
          maxWidth: 350
        });
        m.addListener("click", function () {
          if (typeof self.infoWindow != "undefined") {
            self.infoWindow.close();
          }

          iw.open(self.map, m);
          self.infoWindow = iw;
        }); // eslint-disable-next-line no-undef

        this.google.maps.event.addListener(iw, "domready", function () {
          var el = document.getElementsByClassName("gm-style-iw")[0],
              wr = el.parentElement,
              // eslint-disable-next-line no-unused-vars
          bk = wr.firstChild,
              // eslint-disable-next-line no-unused-vars
          close = wr.getElementsByTagName("button")[0],
              // eslint-disable-next-line no-unused-vars
          closeIcon = document.createElement("i");
        });
      }
    }

    this.cluster = new MarkerClusterer({
      map: this.map,
      markers: this.markers
    });
  }

  getPosition(lat, lng) {
    // eslint-disable-next-line no-undef
    return new this.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
  }

  setCenterPosition() {
    //calcolo la posizione su cui centrare la mappa e relativo zoom
    var self = this,
        // eslint-disable-next-line no-undef
    geocoder = new this.google.maps.Geocoder(); // function getCountry(components){
    // 	var country = {};
    // 	for (var i = 0; i < components.length; i++) {
    // 		if(components[i].types[0] == 'country'){
    // 			country = components[i];
    // 		}
    // 	}
    // 	return country;
    // }

    if (this.country) {
      if (geocoder) {
        geocoder.geocode({
          address: this.country
        }, function (result) {
          self.map.fitBounds(result[0].geometry.bounds);
        });
      }
    }
  }

  listHeight() {
    let map = this.$refs["map_element"],
        filter = this.$refs["filter-list"]; // @ts-ignore

    if (map && filter) return map.offsetHeight - filter.offsetHeight;else return 0;
  }

  onCenterChanged(newV) {
    this.map.setCenter(newV);
  }

  onStoresChanged() {
    this.addMapMarkers();
  }

  onCountryChanged(newVal) {
    this.updateStores(newVal);
    this.setCenterPosition();
    this.addMapMarkers();
  }

};

__decorate([Watch("center")], StoreLocator.prototype, "onCenterChanged", null);

__decorate([Watch("stores", {
  deep: true
})], StoreLocator.prototype, "onStoresChanged", null);

__decorate([Watch("country")], StoreLocator.prototype, "onCountryChanged", null);

StoreLocator = __decorate([Component({})], StoreLocator);
export default StoreLocator;