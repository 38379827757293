import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store/index';
import VueI18n from 'vue-i18n';
import CanvasPlugin from 'configurator-base-app';
import Cookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import VueWaypoint from 'vue-waypoint'; // import './stylus/main.styl'

import 'animate.css/animate.min.css';
import "./theme/custom.less";
import './assets/configurator.less';
Vue.use(VueI18n);
Vue.use(VueWaypoint);
Vue.use(Cookies);
const i18n = new VueI18n({
  locale: 'IT',
  //messages,
  fallbackLocale: 'EN'
}); // Configurazione configurator-base-app

const settings = {
  VUE_APP_REST_URL: `${process.env.VUE_APP_REST_URL}`,
  VUE_APP_CONFIG_IDENTIFIER: `${process.env.VUE_APP_CONFIG_IDENTIFIER}`,
  // TODO: ABILITARE LE ESCLUSIVE DECOMMENTANDO SOTTO
  VUE_APP_CONFIG_ESCLUSIVA: `${process.env.VUE_APP_CONFIG_ESCLUSIVA}`,
  VUE_APP_COLORS_URL: `${process.env.VUE_APP_COLORS_URL}`,
  VUE_APP_MODEL_URL: `${process.env.VUE_APP_MODEL_URL}`,
  VUE_APP_CONFIG_DATABASE: `${process.env.VUE_APP_CONFIG_DATABASE}`,
  VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATE_ID: process.env.VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATE_ID,
  VUE_APP_SENDGRID_API_KEY: process.env.VUE_APP_SENDGRID_API_KEY,
  VUE_APP_GTAG_ID: `${process.env.VUE_APP_GTAG_ID}`
};
Vue.prototype.$iperStoresMode = process.env.VUE_APP_IPER_STORES_MODE; // @ts-ignore

Vue.use(CanvasPlugin, {
  store,
  settings
});
Vue.config.productionTip = false;
Vue.filter('capitalize', str => {
  const strLower = str.toLowerCase();
  return strLower.charAt(0).toUpperCase() + strLower.slice(1);
});
Vue.filter('upperCase', s => {
  if (typeof s !== 'string') return '';
  return s.toUpperCase();
});
Vue.filter('decodeLensModel', l => {
  if (typeof l !== 'string') {
    return '';
  } else {
    if (l == 'MF1.50SF-UC') {
      return 'MONOFOCALE 1.50 SFERICA-UC';
    } else if (l == 'MF1.56SF-HMC') {
      return 'MONOFOCALE 1.56 SFERICA-HMC';
    } else if (l == 'MF1.56SF-HMC BLUCUT') {
      return 'MONOFOCALE 1.56 SFERICA-HMC BLUCUT';
    } else if (l == 'MF1.60AS-SHMC') {
      return 'MONOFOCALE 1.60 ASFERICA-SHMC';
    } else if (l == 'MF1.67AS-SHMC') {
      return 'MONOFOCALE 1.67 ASFERICA-SHMC';
    }
  }
});
new Vue({
  i18n,
  store,
  vuetify,
  render: h => h(App),

  created() {
    // @ts-ignore
    Vue.component('Canvas3d', this.$canvas3d); // @ts-ignore

    this.$rest.getCountryCode().then(response => {
      i18n.locale = response.data;
    }); // @ts-ignore

    this.$rest.getTranslations().then(translations => {
      const messages = translations.data;

      for (const key in messages) {
        const allLocaleMessages = messages[key]["message"];
        const availableLocaleMessages = []; // Check which messages are already translated
        // Don't consider all the other ones

        for (const [msg, translated] of Object.entries(allLocaleMessages)) {
          if (translated != "") {
            availableLocaleMessages[msg] = translated;
          }
        }

        i18n.setLocaleMessage(key, {
          message: availableLocaleMessages
        });
      }
    });
  }

}).$mount('#app');