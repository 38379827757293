import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
let ShareButton = class ShareButton extends Vue {
  constructor() {
    super(...arguments);
    this.dialog = false;
    this.emailFrom = "";
    this.emailTo = "";
    this.notes = "";
    this.emailSend = false;
    this.errorEmail = false;
  }

  get colore() {
    return this.$store.state.configurator.colore;
  }

  get configurationComplete() {
    return this.$store.getters["configurator/configurationComplete"];
  }

  get id_for_url() {
    return this.$store.state.configurator.id_for_url;
  }

  get emailSent() {
    return this.$store.state.configurator.emailSent;
  }

  get activeConfiguration() {
    return this.$store.getters["configurator/getActiveConfiguration"];
  }

  get qrcode() {
    return this.$store.state.configurator.qrcode;
  }

  set qrcode(v) {
    this.$store.state.configurator.qrcode = v;
  }

  get qrcodeUrl() {
    return this.$store.state.configurator.qrcodeUrl;
  }

  set qrcodeUrl(v) {
    this.$store.state.configurator.qrcodeUrl = v;
  }

  get positionForPrint() {
    return this.$store.state.configurator.positionForPrint;
  }

  set positionForPrint(value) {
    this.$store.commit("configurator/setPositionForPrint", value);
  }

  set emailSent(value) {
    this.$store.commit("configurator/setEmailSent", value);
  }

  screenshotAndSendSendGridMail(input) {
    this.$store.dispatch("configurator/screenshotAndSendSendGridMail", input);
  }

  setIdForUrl() {
    return this.$store.dispatch("configurator/setIdForUrl");
  }

  makeQRCode(id) {
    let QRCode = require("qrcode");

    let url = window.location.href;
    let id1 = url.split("/");

    if (parseInt(id1[id1.length - 1]) > 0) {
      id1.pop();
      this.qrcodeUrl = id1.join("/") + "/" + id;
      return QRCode.toDataURL(id1.join("/") + "/" + id);
    } else {
      this.qrcodeUrl = window.location.href + id;
      return QRCode.toDataURL(window.location.href + id);
    }
  }

  async openShareDialog() {
    // Prepara la posizione del frontale
    this.positionForPrint = true; // Dopo 750ms la posizione sarà corretta: mostra dialog

    setTimeout(() => {
      this.setIdForUrl().then(async () => {
        this.qrcode = await this.makeQRCode(this.id_for_url);
        this.dialog = true;
      });
    }, 750);
  }

  closeDialog() {
    this.dialog = false;
    this.emailSend = false;
    this.errorEmail = false;
  }

  condividi() {
    /**
     * al click setto la variabile true per poter compilare il template e aprire la condivisione
     * per poi riportarla a false a fine ciclo tramite propos / $emit
     */
    let data = {},
        emailFrom = this.emailFrom,
        emailTo = this.emailTo,
        isGmail = this.emailTo.includes("@gmail.com");
    let finFrontale = this.activeConfiguration.finitura == "S" ? "LUCIDO" : this.activeConfiguration.finitura == "N" ? "NATURALE" : "OPACO",
        finAste = this.activeConfiguration.finituraAste == "S" ? "LUCIDO" : "OPACO",
        colFrontale = this.activeConfiguration.colore.U_THE_COLORE.replace("PL", ""),
        colAste = this.activeConfiguration.coloreAste.U_THE_COLORE.replace("PL", ""),
        colEst = "",
        colInt = "";

    if (this.colore.Category == "MULTISTRATO") {
      colEst = "https://media.thema-optical.com/colori_acetato/frontali/" + this.colore.U_THE_COLORE + ".jpg";
      colInt = "https://media.thema-optical.com/colori_acetato/frontali/" + this.colore.U_THE_COLORE + "_b.jpg";
    }

    data = {
      modello: this.activeConfiguration.frontale.U_THE_MODELLO,
      calibro: this.activeConfiguration.calibro,
      ponte: this.activeConfiguration.naso,
      coloreFrontale: colFrontale,
      finFrontale: finFrontale,
      misuraAste: this.activeConfiguration.misuraAste,
      coloreAste: colAste,
      finAste: finAste,
      nomeFrontale: this.activeConfiguration.nome,
      colNomeFrontale: this.activeConfiguration.coloreNome,
      // note: this.activeConfiguration.note,
      message: this.notes,
      qrcode: this.qrcode,
      qrcodeUrl: this.qrcodeUrl,
      colEst: colEst,
      colInt: colInt,
      id: this.id_for_url,
      isGmail: isGmail
    };
    this.screenshotAndSendSendGridMail({
      data,
      emailFrom,
      emailTo
    });
  }

  onEmailSentChanged(value) {
    if (value != undefined) {
      if (value) {
        this.emailSend = true;
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
        this.emailSend = false;
      }

      this.emailSent = undefined;
    }
  }

};

__decorate([Watch("emailSent")], ShareButton.prototype, "onEmailSentChanged", null);

ShareButton = __decorate([Component({})], ShareButton);
export default ShareButton;