import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
let ShowCartButton = class ShowCartButton extends Vue {
  get profileOpen() {
    return this.$store.state.profileOpen;
  }

  set profileOpen(value) {
    this.$store.commit("setProfileOpen", value);
  }

  get currentTab() {
    return this.$store.state.currentTab;
  }

  set currentTab(value) {
    this.$store.commit("setCurrentTab", value);
  }

};

__decorate([Prop({
  default: false
})], ShowCartButton.prototype, "canShowCart", void 0);

__decorate([Prop({
  default: 0
})], ShowCartButton.prototype, "cartTotal", void 0);

ShowCartButton = __decorate([Component({})], ShowCartButton);
export default ShowCartButton;