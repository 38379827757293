import "core-js/modules/es.string.replace-all.js";
import { __decorate } from "tslib";
/* eslint-disable no-undef */

import { Component, Vue, Prop, Watch } from "vue-property-decorator"; //import '../plugins/vuetify' //forse non necessario su ogni componente

import _ from "lodash";
import hideMobileKeyboardOnReturn from "../plugins/utils";
let Frontali = class Frontali extends Vue {
  constructor() {
    super(...arguments);
    this.element = {
      title: "Forma",
      subtitle: "Scegli la forma frontale"
    };
    this.open = false;
    this.query = "";
    this.selectedSize = null;
    this.animateSize = false;
    this.noSizeSelectedDialog = false;
    this.previousSize = "";
    this.showSunModelNotAvailable = false;
  }

  mounted() {
    this.selectedFrontType = "V"; // By default, set the front type to "V"
  }

  get selectedFrontType() {
    return this.$store.state.configurator.glassType;
  }

  set selectedFrontType(val) {
    this.$store.commit("configurator/setLensesType", val, {
      root: true
    }); // If the selected model is sun, check whether the sun version is actually available

    if (val == "S") {
      const filtered = this.items.filter(i => {
        return i.U_THE_CUSMODEL == "C" && i.U_TIPO_VSP == "S";
      }); // If the corresponding sun model is not found, show an error to the user and bring him back to sight models

      if (filtered.find(f => {
        return f.U_THE_MODELLO == this.frontale.U_THE_MODELLO.replaceAll("C", "SC");
      }) == undefined) {
        this.showSunModelNotAvailable = true;
        return;
      }
    }

    this.$store.commit("setLenteDx", null);
    this.$store.commit("setLenteSx", null);
    this.$store.commit("resetLentiVista");
    this.$store.commit("configurator/lenti/resetCfgLenteVistaDx");
    this.$store.commit("configurator/lenti/resetCfgLenteVistaSx");
  }

  get calibro() {
    const parsed = parseInt(this.$store.state.configurator.calibro);
    return isNaN(parsed) ? 0 : parsed;
  }

  get naso() {
    const parsed = parseInt(this.$store.state.configurator.naso);
    return isNaN(parsed) ? 0 : parsed;
  }

  get gtagId() {
    return this.$store.state.gtagId;
  }

  get frontaliniMeniscati() {
    return this.$store.state.frontaliniMeniscati;
  }

  get frontale() {
    return this.$store.state.configurator.frontale;
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get sizes() {
    return this.$store.state.configurator.frontali.itemsSizes;
  }

  get items() {
    // Get all items and sort them based on the number present after the "." character
    return this.$store.state.configurator.frontali.items.sort((a, b) => {
      const id1 = parseInt(a.U_THE_MODELLO.substring(a.U_THE_MODELLO.lastIndexOf(".")).replace(/\D/g, ""));
      const id2 = parseInt(b.U_THE_MODELLO.substring(b.U_THE_MODELLO.lastIndexOf(".")).replace(/\D/g, ""));
      if (id1 > id2) return -1;
      if (id1 < id2) return 1;
      return 0;
    });
  }

  get asianFitting() {
    return this.$store.state.configurator.asianFitting;
  }

  set asianFitting(af) {
    this.$store.commit("configurator/setAsianFitting", af);
  }

  get elementTitle() {
    return _.upperCase(this.element.title);
  }

  get filteredItems() {
    var query = this.query;
    let fData = this.items.filter(i => {
      if (this.selectedFrontType == "V") {
        return i.U_THE_CUSMODEL == "C" && i.U_TIPO_VSP == "V";
      } else {
        return i.U_THE_CUSMODEL == "C" && i.U_TIPO_VSP == "S";
      }
    });

    if (this.query && this.query != "" && fData) {
      return fData.filter(function (item) {
        return _.includes(item.U_THE_MODELLO, query);
      });
    } else {
      return fData;
    }
  }

  get itemSizes() {
    let front = this.frontale;

    if (front && this.sizes.length > 0) {
      return this.sizes.filter(function (s) {
        return s.U_THE_MODELLO == front.U_THE_MODELLO;
      });
    }

    return [];
  }

  get finitura() {
    return this.$store.state.finitura;
  }

  set finitura(f) {
    this.$store.commit("configurator", "setFinishing", f);
  }

  get asianFittingText() {
    return this.asianFitting ? "YES " + this.$t("message.Asian_fitting") : "NO " + this.$t("message.Asian_fitting");
  }

  selectSizeFromSizes() {
    const s = this.itemSizes.filter(is => {
      return is.ItemCode == this.frontale.ItemCode && parseInt(is.size_eye) == this.calibro && parseInt(is.size_bridge) == this.naso;
    });

    if (s.length > 0) {
      this.selectedSize = s[0].id;
    }
  }

  setFrontal(value) {
    this.$store.commit("configurator/setFrontal", value);
  }

  setSize(value) {
    this.$store.commit("configurator/setSize", value);
  }

  clearSize() {
    this.$store.commit("configurator/setSize", {
      size_eye: "",
      size_bridge: ""
    });
  }

  setAsianFitting(value) {
    this.$store.commit("configurator/setAsianFitting", value);
  }

  setSunLensesModel(value) {
    this.$store.commit("configurator/setSunLensesModel", value);
  }

  setFrontaliniMeniscati(d) {
    this.$store.commit("setFrontaliniMeniscati", d);
  }

  hideMobileKeyboardOnReturn(e) {
    hideMobileKeyboardOnReturn(e);
  }

  setFrame(item) {
    this.setFrontal(item);

    if (this.selectedSize) {
      this.selectedSize = null;
      this.clearSize();
    }
  }

  isItemActive(item) {
    var _this$frontale;

    return ((_this$frontale = this.frontale) === null || _this$frontale === void 0 ? void 0 : _this$frontale.ItemCode) == (item === null || item === void 0 ? void 0 : item.ItemCode);
  }

  clearLenses() {
    this.$store.commit("setLenteSx", null);
    this.$store.commit("setLenteDx", null);
  }

  closeDialogErroreLenti() {
    this.$store.commit("openCloseDialogErroreLenti", false);
  }

  resetPreviousLenses() {
    this.$store.commit("configurator/setOftLensesDxModel", this.$store.state.lenteDx);
    this.$store.commit("configurator/setOftLensesSxModel", this.$store.state.lenteSx);
  }

  onSelectedFrontTypeChanged(newVal) {
    this.setSunLensesModel(null);
    const m = this.items.filter(i => {
      return i.U_THE_MODELLO.replace("SC", "").replace("C", "") == this.frontale.U_THE_MODELLO.replace("SC", "").replace("C", "") && i.U_TIPO_VSP == newVal;
    });

    if (m.length > 0) {
      this.setFrontal(m[0]);
    }

    if (this.calibro && this.naso) {
      this.selectSizeFromSizes();
    }
  }

  onSelectedSizeChanged(newVal) {
    let s = this.itemSizes.filter(s => {
      return s.id == newVal;
    });

    if (s.length > 0) {
      const foundSize = this.sizes.find(el => el.U_THE_MODELLO == this.frontale.U_THE_MODELLO && Number(el.size_eye) == this.calibro && Number(el.size_bridge) == this.naso);
      this.previousSize = foundSize == undefined ? null : foundSize.id;
      this.setSize(s[0]);
      this.noSizeSelectedDialog = false;
    } else {
      this.clearSize();
    }
  }

  onRevealSettingChanged(newVal) {
    //add animation class to element
    let self = this;

    if (newVal == "frontSize") {
      this.animateSize = true;
      setTimeout(function () {
        self.animateSize = false;
        self.$emit("reset-setting-item");
      }, 2000);
    }

    if (newVal == "frontSize") {
      this.animateSize = true;
      setTimeout(function () {
        self.animateSize = false;
        self.$emit("reset-setting-item");
      }, 2000);
    }
  }

  onStepperChangeChanged(newVal) {
    if (newVal[0] == 1 && this.calibro == 0) {
      //change from step 1
      this.noSizeSelectedDialog = true;
    }
  }

  onFrontaleChanged(newVal) {
    // @ts-ignore
    ga("create", this.gtagId, "auto", "frontaleglobal"); // @ts-ignore

    ga("frontaleglobal.send", "event", "frontale", "setForma", newVal.U_THE_MODELLO);
  }

  onCalibroChanged() {
    // // @ts-ignore
    // ga("create", this.gtagId, "auto", "frontaleglobal");
    // // @ts-ignore
    // ga(
    //   "frontaleglobal.send",
    //   "event",
    //   "frontale",
    //   "setMisure",
    //   this.frontale.U_THE_MODELLO + " - " + newVal + "/" + this.naso
    // );
    if (this.naso) {
      this.selectSizeFromSizes();
    }
  }

  onNasoChanged() {
    if (this.calibro) {
      this.selectSizeFromSizes();
    }
  }

  onItemSizesChanged() {
    if (this.calibro && this.naso) {
      this.selectSizeFromSizes();
    }
  }

  onUserChanged() {
    // @ts-ignore
    this.$rest.getFrontaliniMeniscati().then(response => {
      let d = response.data;
      d = Object.values(d);
      this.setFrontaliniMeniscati(d);
    });
  }

};

__decorate([Prop({
  default: false
})], Frontali.prototype, "isOpen", void 0);

__decorate([Prop({
  default: false
})], Frontali.prototype, "revealSetting", void 0);

__decorate([Prop({
  default: false
})], Frontali.prototype, "darkTheme", void 0);

__decorate([Prop()], Frontali.prototype, "stepperChange", void 0);

__decorate([Watch("selectedFrontType")], Frontali.prototype, "onSelectedFrontTypeChanged", null);

__decorate([Watch("selectedSize")], Frontali.prototype, "onSelectedSizeChanged", null);

__decorate([Watch("revealSetting")], Frontali.prototype, "onRevealSettingChanged", null);

__decorate([Watch("stepperChange")], Frontali.prototype, "onStepperChangeChanged", null);

__decorate([Watch("frontale")], Frontali.prototype, "onFrontaleChanged", null);

__decorate([Watch("calibro")], Frontali.prototype, "onCalibroChanged", null);

__decorate([Watch("naso")], Frontali.prototype, "onNasoChanged", null);

__decorate([Watch("itemSizes")], Frontali.prototype, "onItemSizesChanged", null);

__decorate([Watch("user")], Frontali.prototype, "onUserChanged", null);

Frontali = __decorate([Component({
  filters: {
    capitalize: function (value) {
      return _.capitalize(value);
    }
  }
})], Frontali);
export default Frontali;