import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import hideMobileKeyboardOnReturn from "../plugins/utils";
let LentiVista = class LentiVista extends Vue {
  constructor() {
    super(...arguments);
    this.lente = null;
    this.chooseLenses = false;
    this.altPupWarn = false;
    this.mobileLensSelected = 0;
    this.hideMobileKeyboardOnReturn = hideMobileKeyboardOnReturn;
  }

  get themeColor() {
    return this.darkTheme ? "grey darken-2" : "grey lighten-2";
  }

  get user() {
    return this.$store.state.configurator.user.user;
  }

  get reference() {
    return this.$store.state.configurator.note;
  }

  set reference(value) {
    this.$store.commit("configurator/setRowNotes", value);
  }

  get lensesAreSelected() {
    return !_.isEmpty(this.$store.state.configurator.lenteVistaSx) && !_.isEmpty(this.$store.state.configurator.lenteVistaDx);
  }

  get lenteSx() {
    return this.$store.state.lenteSx;
  }

  set lenteSx(value) {
    this.$store.commit("setLenteSx", value);
  }

  get lenteDx() {
    return this.$store.state.lenteDx;
  }

  set lenteDx(value) {
    this.$store.commit("setLenteDx", value);
  }

  get sferaDx() {
    return this.$store.state.configurator.lenti.sferaDx;
  }

  set sferaDx(value) {
    this.$store.commit("configurator/lenti/setSferaDx", value);
  }

  get sferaSx() {
    return this.$store.state.configurator.lenti.sferaSx;
  }

  set sferaSx(value) {
    this.$store.commit("configurator/lenti/setSferaSx", value);
  }

  get cilindroDx() {
    return this.$store.state.configurator.lenti.cilindroDx;
  }

  set cilindroDx(value) {
    this.$store.commit("configurator/lenti/setCilindroDx", value);
  }

  get cilindroSx() {
    return this.$store.state.configurator.lenti.cilindroSx;
  }

  set cilindroSx(value) {
    this.$store.commit("configurator/lenti/setCilindroSx", value);
  }

  get asseDx() {
    return this.$store.state.configurator.lenti.asseDx;
  }

  set asseDx(value) {
    this.$store.commit("configurator/lenti/setAsseDx", value);
  }

  get asseSx() {
    return this.$store.state.configurator.lenti.asseSx;
  }

  set asseSx(value) {
    this.$store.commit("configurator/lenti/setAsseSx", value);
  }

  get semiDistDx() {
    return this.$store.state.configurator.lenti.semiDistDx;
  }

  set semiDistDx(value) {
    this.$store.commit("configurator/lenti/setSemiDistDx", value);
  }

  get semiDistSx() {
    return this.$store.state.configurator.lenti.semiDistSx;
  }

  set semiDistSx(value) {
    this.$store.commit("configurator/lenti/setSemiDistSx", value);
  }

  get altPupDx() {
    return this.$store.state.configurator.lenti.altPupDx;
  }

  set altPupDx(value) {
    this.$store.commit("configurator/lenti/setAltPupDx", value);
  }

  get altPupSx() {
    return this.$store.state.configurator.lenti.altPupSx;
  }

  set altPupSx(value) {
    this.$store.commit("configurator/lenti/setAltPupSx", value);
  }

  get addDx() {
    return this.$store.state.configurator.lenti.addDx;
  }

  set addDx(value) {
    this.$store.commit("configurator/lenti/setAddDx", value);
  }

  get addSx() {
    return this.$store.state.configurator.lenti.addSx;
  }

  set addSx(value) {
    this.$store.commit("configurator/lenti/setAddSx", value);
  }

  get lentiVista() {
    return this.$store.getters["configurator/lenti/oftLenses"];
  }

  get modelliLenti() {
    return _.unionBy(this.lentiVista, "U_THE_MODELLO");
  }

  get lenteVistaDx() {
    return this.$store.state.configurator.lenteVistaDx;
  }

  get lenteVistaSx() {
    return this.$store.state.configurator.lenteVistaSx;
  }

  get calibro() {
    const parsed = parseInt(this.$store.state.configurator.calibro);
    return isNaN(parsed) ? 0 : parsed;
  }

  get naso() {
    const parsed = parseInt(this.$store.state.configurator.naso);
    return isNaN(parsed) ? 0 : parsed;
  }

  get note() {
    return this.$store.state.configurator.note;
  }

  get assi() {
    const a = [];
    let start = 0;

    do {
      const obj = {
        value: 0,
        text: ""
      };
      obj.value = start;
      obj.text = start.toString();
      a.push(obj);
      start = start + 1;
    } while (start <= 180);

    return a;
  }

  get semiDist() {
    const sd = [];
    let start = 15.0;

    do {
      const obj = {
        value: 0,
        text: ""
      };
      obj.value = start;
      obj.text = start.toFixed(1);
      sd.push(obj);
      start = start + 0.5;
    } while (start <= 40);

    return sd;
  }

  get sfere() {
    const s = [];
    let start = Math.min.apply(Math, this.lentiVista.map(function (o) {
      return o.U_THE_SFERA;
    }));

    do {
      const obj = {
        value: 0,
        text: ""
      };
      obj.value = start;
      obj.text = start.toFixed(2);
      s.push(obj);
      start = start + 0.25;
    } while (start <= Math.max.apply(Math, this.lentiVista.map(function (o) {
      return o.U_THE_SFERA;
    })));

    return s;
  }

  get cilindri() {
    const s = [];
    let start = Math.min.apply(Math, this.lentiVista.map(function (o) {
      return o.U_THE_CILINDRO;
    }));

    do {
      const obj = {
        value: 0,
        text: ""
      };
      obj.value = start;
      obj.text = start.toFixed(2);
      s.push(obj);
      start = start + 0.25;
    } while (start <= Math.max.apply(Math, this.lentiVista.map(function (o) {
      return o.U_THE_CILINDRO;
    })));

    return s;
  }

  get lentiFiltered() {
    return this.lentiFilteredDx.filter(d => {
      return this.lentiFilteredSx.some(r => r.U_THE_MODELLO == d.U_THE_MODELLO);
    });
  }

  get lentiFilteredDx() {
    const fld = this.lentiVista.filter(lf => {
      var _this$sferaDx, _this$cilindroDx;

      let sferaDx = (_this$sferaDx = this.sferaDx) !== null && _this$sferaDx !== void 0 ? _this$sferaDx : 0;
      let cilindroDx = (_this$cilindroDx = this.cilindroDx) !== null && _this$cilindroDx !== void 0 ? _this$cilindroDx : 0;
      return (lf.U_THE_SFERA == sferaDx && lf.U_THE_CILINDRO == cilindroDx || lf.U_THE_TRASP_POSITIVA == sferaDx && lf.U_THE_TRASP_NEGATIVA == cilindroDx) && this.alphaDx < lf.U_THE_DIAMETRO / 2 && this.betaDx < lf.U_THE_DIAMETRO / 2;
    });
    let lenteOk = fld.find(el => this.lenteDx ? el.U_THE_MODELLO == this.lenteDx.U_THE_MODELLO : false); // Se la lente non è disponibile dopo aver applicato il filtro, bisogna toglierla

    if (this.lenteDx != null && lenteOk == undefined) {
      this.$store.commit("openCloseDialogErroreLenti", true);
      this.unsetLenses();
    } else {
      // Aggiorna la lente in base ai nuovi filtri
      if (lenteOk) {
        this.lenteDx = lenteOk;
      }
    }

    return fld;
  }

  get lentiFilteredSx() {
    const fls = this.lentiVista.filter(lf => {
      var _this$sferaSx, _this$cilindroSx;

      let sferaSx = (_this$sferaSx = this.sferaSx) !== null && _this$sferaSx !== void 0 ? _this$sferaSx : 0;
      let cilindroSx = (_this$cilindroSx = this.cilindroSx) !== null && _this$cilindroSx !== void 0 ? _this$cilindroSx : 0;
      return (lf.U_THE_SFERA == sferaSx && lf.U_THE_CILINDRO == cilindroSx || lf.U_THE_TRASP_POSITIVA == sferaSx && lf.U_THE_TRASP_NEGATIVA == cilindroSx) && this.alphaSx < lf.U_THE_DIAMETRO / 2 && this.betaSx < lf.U_THE_DIAMETRO / 2;
    });
    let lenteOk = fls.find(el => this.lenteSx ? el.U_THE_MODELLO == this.lenteSx.U_THE_MODELLO : false); // Se la lente non è disponibile dopo aver applicato il filtro, bisogna toglierla

    if (this.lenteSx != null && lenteOk == undefined) {
      this.$store.commit("openCloseDialogErroreLenti", true);
      this.unsetLenses();
    } else {
      // Aggiorna la lente in base ai nuovi filtri
      if (lenteOk) {
        this.lenteSx = lenteOk;
      }
    }

    return fls;
  }

  get alphaDx() {
    // -1 => valore trovato facendo delle prove con la macchina taglia lenti che, probabilmente,
    // tiene conto di alcune tolleranze e spessori che noi non avevamo calcolato
    let a = this.semiDistDx - 1 - this.naso / 2;
    return a > 0 ? a : 0;
  }

  get betaDx() {
    let b = this.calibro - this.alphaDx;
    return b > 0 ? b : 0;
  }

  get alphaSx() {
    // -1 => valore trovato facendo delle prove con la macchina taglia lenti che, probabilmente,
    // tiene conto di alcune tolleranze e spessori che noi non avevamo calcolato
    let a = this.semiDistSx - 1 - this.naso / 2;
    return a > 0 ? a : 0;
  }

  get betaSx() {
    let b = this.calibro - this.alphaSx;
    return b > 0 ? b : 0;
  }

  rif(val) {
    if (val.indexOf("Rif.") < 0 && this.note != "") {
      this.$store.commit("configurator/setRowNotes", "Rif. " + val);
    }
  }

  unsetLenses() {
    if (this.chooseLenses) {
      this.lenteDx = null;
      this.lenteSx = null;
    }

    this.lente = null;
    this.$store.commit("resetLentiVista");
  }

  closeAndReset() {
    this.$store.commit("resetLentiVista");
    this.$store.commit("configurator/lenti/resetCfgLenteVistaDx");
    this.$store.commit("configurator/lenti/resetCfgLenteVistaSx");
    this.lenteDx = null;
    this.lenteSx = null;
    this.lente = null;
  }

  setLens(l) {
    this.lente = l;
    this.lenteDx = this.lentiFilteredDx.filter(d => {
      return l.U_THE_MODELLO == d.U_THE_MODELLO;
    })[0];
    this.lenteSx = this.lentiFilteredSx.filter(d => {
      return l.U_THE_MODELLO == d.U_THE_MODELLO;
    })[0];
  }

  setOftLensesDxModel(l) {
    this.$store.commit("configurator/setOftLensesDxModel", l);
  }

  setOftLensesSxModel(l) {
    this.$store.commit("configurator/setOftLensesSxModel", l);
  }

  resetCfgLenteVistaDx() {
    this.$store.commit("configurator/lenti/resetCfgLenteVistaDx");
  }

  resetCfgLenteVistaSx() {
    this.$store.commit("configurator/lenti/resetCfgLenteVistaSx");
  }

  conferma() {
    if (this.lenteDx != null && this.lenteSx != null) {
      this.setOftLensesDxModel(this.lenteDx);
      this.setOftLensesSxModel(this.lenteSx);
      this.chooseLenses = false;
    }
  }

  onSemiDistDxChange(val) {
    if (val) {
      if (this.semiDistDx + this.semiDistSx < 46 && this.semiDistDx != 0 && this.semiDistSx != 0) {// this.distPupWarn = true;
      }
    }
  }

  onSemiDistSxChange(val) {
    if (val) {
      if (this.semiDistDx + this.semiDistSx < 46 && this.semiDistDx != 0 && this.semiDistSx != 0) {// this.distPupWarn = true;
      }
    }
  }

  onLentiFilteredDxChange(val) {
    if (val.length == 0) {
      this.$store.commit("resetLentiVista");
    }
  }

  onLentiFilteredSxChange(val) {
    if (val.length == 0) {
      this.$store.commit("resetLentiVista");
    }
  }

};

__decorate([Prop({
  default: false
})], LentiVista.prototype, "darkTheme", void 0);

__decorate([Watch("semiDistDx")], LentiVista.prototype, "onSemiDistDxChange", null);

__decorate([Watch("semiDistSx")], LentiVista.prototype, "onSemiDistSxChange", null);

__decorate([Watch("lentiFilteredDx")], LentiVista.prototype, "onLentiFilteredDxChange", null);

__decorate([Watch("lentiFilteredSx")], LentiVista.prototype, "onLentiFilteredSxChange", null);

LentiVista = __decorate([Component({
  filters: {
    capitalize(s) {
      return _.capitalize(s);
    }

  }
})], LentiVista);
export default LentiVista;