import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
let PersonalNamePreview = class PersonalNamePreview extends Vue {};

__decorate([Prop({
  default: "Name"
})], PersonalNamePreview.prototype, "name", void 0);

__decorate([Prop({
  default: null
})], PersonalNamePreview.prototype, "colore", void 0);

__decorate([Prop({
  default: false
})], PersonalNamePreview.prototype, "darkTheme", void 0);

PersonalNamePreview = __decorate([Component], PersonalNamePreview);
export default PersonalNamePreview;