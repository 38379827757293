import Configurator from "./components/Configurator.vue";
import "./plugins/vuetify";
import store from "./store";
import { mapState } from "vuex";
export default {
  store,
  name: "App",
  components: {
    Configurator
  },

  data() {
    return {
      appDark: false
    };
  },

  mounted: function () {
    // eslint-disable-next-line no-undef
    gtag("config", this.gtagId, {
      custom_map: {
        dimension1: "aste",
        dimension2: "coloreMetalloAste",
        dimension3: "finitura",
        dimension4: "asianFitting",
        dimension5: "coloreFrontale",
        dimension6: "misura",
        dimension7: "finituraAste",
        dimension8: "nomePersonalizzato",
        dimension9: "coloreNomePersonalizzato"
      }
    });
  },
  computed: { ...mapState(["gtagId"])
  },
  watch: {
    appDark: function (val) {
      this.$vuetify.theme.dark = val;
    }
  }
};